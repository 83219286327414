/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React from 'react';
import { StaticQuery, graphql } from "gatsby"

// Libraries
import _ from 'lodash'

// Third Party
import { Helmet } from "react-helmet"
import parse from 'html-react-parser'

import favicon from '../img/logo.png'

const query = graphql`
  {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        defaultKeywords: keywords
      }
    }
    file(relativePath: {eq: "logo.png"}) {
      childImageSharp {
        fluid(maxWidth: 243) {
          ...GatsbyImageSharpFluid
        }
        resize(width: 243, quality: 90) {
          src
        }
      }
    }
  }
`;

function SEO({ title, description, image, pathname, article, keywords, yoast }) {
  return (
    <StaticQuery
      query={query}
      render={({
        site: {
          siteMetadata: {
            defaultKeywords,
            defaultTitle,
            defaultDescription,
            siteUrl,
          },
        },
        file: featuredImage
      }) => {
        if (yoast) {
          const yoastTitleObj = _.find(yoast.meta, ({ property }) => property === 'og:title')
          const yoastDescriptionObj = _.find(yoast.meta, ({ name }) => name === 'description')

          const yoastTitle = _.size(yoastTitleObj) > 0 && yoastTitleObj.content ? yoastTitleObj.content : defaultTitle
          const yoastDescription = _.size(yoastDescriptionObj) > 0 && yoastDescriptionObj.content ? yoastDescriptionObj.content : defaultDescription

          title = title || yoastTitle
          description = description || yoastDescription
        }

        const seo = {
          title: title || defaultTitle,
          description: description || defaultDescription,
          image: image ? `${siteUrl}${image}` : `${siteUrl}${featuredImage.childImageSharp.resize.src}`,
          url: `${siteUrl}${pathname || "/"}`,
          keywords: keywords || defaultKeywords
        }

        return (
          <>
            <Helmet title={parse(seo.title.substr(0, 60))}>
              <html lang="nl" />
              <link rel="icon" href={favicon} />
              <meta name="description" content={seo.description.substr(0, 150)} />
              <meta name="keywords" content={seo.keywords} />
              <meta name="image" content={seo.image} />
              <meta name="og:site_name" content={defaultTitle} />
              {seo.url && <meta property="og:url" content={seo.url} />}
              {(article ? true : null) && (
                <meta property="og:type" content="article" />
              )}
              {seo.title && <meta property="og:title" content={parse(seo.title)} />}
              {seo.description && (
                <meta property="og:description" content={seo.description} />
              )}
              {seo.image && <meta property="og:image" itemProp="image" content={seo.image} />}
              <meta name="twitter:card" content="summary_large_image" />
              {seo.title && <meta name="twitter:title" content={seo.title} />}
              {seo.description && (
                <meta name="twitter:description" content={seo.description} />
              )}
              {seo.image && <meta name="twitter:image" content={seo.image} />}
              <meta property="og:locale" content="nl_NL" />
              <meta property="og:locale:alternate" content="en_GB" />

              <script>
                {`
                 !function (w, d, t) {   w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};   ttq.load('CDRKH4BC77UF0POATS60');   ttq.page(); }(window, document, 'ttq'); 
                `}
              </script>
              <script async src="https://www.googletagmanager.com/gtag/js?id=DC-12632000" />
              <script>
                {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());

                  gtag('config', 'DC-12632000');
                `}
              </script>
              <script>
                {`
                  gtag('event', 'conversion', {
                    'allow_custom_scripts': true,
                    'send_to': 'DC-12632000/pagev0/homep0+standard'
                  });
                `}
              </script>
              <noscript>
                {`
                  <img 
                    src="https://ad.doubleclick.net/ddm/activity/src=12632000;type=pagev0;cat=homep0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=\${GDPR};gdpr_consent=\${GDPR_CONSENT_755};ord=1?" 
                    width="1" 
                    height="1" 
                    alt=""
                  />
                `}
              </noscript>
            </Helmet>
          </>
        )
      }}
    />
  )
}

export default SEO;
